import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {graphql} from 'gatsby'
import {YMaps} from 'react-yandex-maps'
import {Col, Row, Container} from "react-bootstrap"

import ContactsMap from '../components/contactsMap'

import Phones from "../components/phones"

const Page = (props) => (
  <Layout location={props.location}>
    <SEO
      title="Контакты"
      description="Контактная информация компании ООО «Алмет»: адрес, email, телефон, время работы."
    />
    <Container>
      <div className="bg-white py-3 px-2">

        <Row>
          <Col lg="12" className="py-4">
            <h1 className="mb-3">Контакты «Алмет»</h1>

            <div>Связаться с менеджером можно по телефону или WhatsApp</div>  
            <Phones phones={[
               {number: '8(3852)23-77-77', type: 'phone', reachGoal: "9559"},
               {number: '8(964)087 77 77', type: 'whatsapp', reachGoal: "9560"},
            ]} className="btn p-0 mr-3" />
                
                <YMaps>
            {props.data.site.siteMetadata.contacts.map((item, index) => (
              <Row key={index} className="mb-5">
                <Col lg="6">
                  <h3>{item.city}</h3>
                  <p>{item.address}</p>
                  <p>
                    <Phones phones={item.phones} className="btn p-0 pr-2"/>
                  </p>
                  <p>E-mail: {item.email}</p>
                  <p>Режим работы: {item.time}</p>
                </Col>
                <Col lg="6">
                  <ContactsMap coordinates={item.coordinates} />
                </Col>
              </Row>
            ))}
            </YMaps>
          </Col>
        </Row>
      </div>

    </Container>

  </Layout>
)

export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        contacts {
          phones {
            number
            type
            reachGoal
          }
          address
          city
          coordinates
          email
          time
        }
      }
    }
  }
`
